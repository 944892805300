// AuthComponent.js

import React, { useState } from 'react';
import axios from 'axios';
//import { useHistory } from 'react-router-dom';

function AuthComponent() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
  //  const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://football-tracker.de/api/token-auth/', { username, password });
            localStorage.setItem('token', response.data.token);
            //navigate('/dashboard'); // Redirect nach erfolgreichem Login
            window.location.href = '/';
        } catch (error) {
            console.error('Fehler beim Einloggen:', error);
        }
    };

    return (
        <div className="columns is-fullheight is-centered is-vcentered">
            <div className="column is-half">
                <h1 className="title">Login</h1>
                <form onSubmit={handleSubmit}>
                    <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                        <input
                        className="input"
                        type="email"
                        placeholder="Email"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        />
                    </div>
                    </div>
                    <div className="field">
                    <label className="label">Passwort</label>
                    <div className="control">
                        <input
                        className="input"
                        type="password"
                        placeholder="Passwort"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        />
                    </div>
                    </div>
                    <div className="field">
                    <div className="control">
                        <button className="button is-primary" type="submit">Einloggen</button>
                    </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AuthComponent;
