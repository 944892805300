
// App.js
//import "./main.sass";
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axiosInstance from './axiosInstance';
import AuthComponent from './AuthComponent';
import Dashboard from './Dashboard';
import Menu from './Menu';
import Neu from './Neu';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const fetchTeams = async () => {
            if(!localStorage.getItem('token')) {
                return <AuthComponent />
            } 
            try {
                const response = await axiosInstance.get('/user_management/users/organisations/'); // Annahme: Endpunkt zum Abrufen der Teams
                setTeams(response.data);
                if (response.data.length > 0) {
                    setSelectedTeam(response.data[0]); // Wähle das erste Team als ausgewähltes Team aus
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Teams:', error);
            }
        };
        fetchTeams();
        
    }, []); // Leeres Array als Abhängigkeit bedeutet, dass dieser Effekt nur einmalig beim Rendern ausgeführt wird

    const handleLogin = () => {
        // Hier kannst du die Logik für die Anmeldung implementieren
        // Zum Beispiel: Überprüfe die Benutzeranmeldeinformationen und aktualisiere den isLoggedIn-Zustand
        setIsLoggedIn(true);
    };

    const handleLogout = () => {
        // Hier kannst du die Logik für die Abmeldung implementieren
        // Zum Beispiel: Setze den isLoggedIn-Zustand auf false zurück
        setIsLoggedIn(false);
        localStorage.removeItem('token');
        window.location.href = '/login';
    };
    
    const handleTeamSelect = (team) => {
        setSelectedTeam(team);
        onTeamSelect(team);
        otherTeams = teams.filter(team => team.id !== selectedTeam?.id);
    };
    
    if(!localStorage.getItem('token')) {
        return <AuthComponent />
    }

    // Filtere die Teams, um das aktuell ausgewählte Team zu entfernen
    const otherTeams = teams.filter(team => team.id !== selectedTeam?.id);

    return (
        <Router>
        <div className='section'>
        <nav id="navbar-main" className="navbar is-fixed-top">
                <div className="navbar-brand">
                <a className="navbar-item is-hidden-desktop jb-aside-mobile-toggle">
                    <span className="icon"><i className="fas fa-home mdi-24px"></i></span>
                </a>
                <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider is-hoverable">
                        <a className="navbar-link is-arrowless">
                            <span className="icon"><i className="fas fa-menu"></i></span>
                            <span>{selectedTeam.name}</span>
                            <span className="icon">
                            <i className="fas fa-chevron-down"></i>
                            </span>
                        </a>
                        <div className="navbar-dropdown">
                            {otherTeams.map((team) => (
                                <><a
                                    key={team.id}
                                    className="navbar-item"
                                    onClick={() => handleTeamSelect(team)}
                                >
                                    <span>{team.name}</span>
                                </a><hr className="navbar-divider"></hr></>
                            ))}
                            
                            
                        </div>
                    </div>
                </div>
                <div className="navbar-brand is-right">
                <a className="navbar-item is-hidden-desktop jb-navbar-menu-toggle" data-target="navbar-menu">
                    <span className="icon"><i className="fas fa-dots-vertical"></i></span>
                </a>
                </div>
                <div className="navbar-menu fadeIn animated faster" id="navbar-menu">
                <div className="navbar-end">
                    
                    <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
                    <a className="navbar-link is-arrowless">
                        <div className="is-user-avatar">
                        </div>
                        <div className="is-user-name"><span>John Doe</span></div>
                        <span className="icon"><i className="fas fa-chevron-down"></i></span>
                    </a>
                    <div className="navbar-dropdown">
                        <a href="profile.html" className="navbar-item">
                        <span className="icon"><i className="fas fa-account"></i></span>
                        <span>My Profile</span>
                        </a>
                        <hr className="navbar-divider" />
                        <a className="navbar-item">
                        <span className="icon"><i className="fas fa-logout"></i></span>
                        <span>Log Out</span>
                        </a>
                    </div>
                    </div>
                    <a href="https://justboil.me/bulma-admin-template/free-html-dashboard/" title="About" className="navbar-item has-divider is-desktop-icon-only">
                    <span className="icon"><i className="fas fa-help-circle-outline"></i></span>
                    <span>About</span>
                    </a>
                    <a title="Log out" className="navbar-item is-desktop-icon-only" onClick={handleLogout}>
                    <span className="icon"><i className="fas fa-logout"></i></span>
                    <span > Abmelden</span>
                    </a>
                </div>
                </div>
            </nav>
            <div className='columns pt-3'>
                <Menu />
                <div className='column'>
                    <h1 className='title'>Application</h1>
                    
                        <Routes>
                            
                            <Route path="/" element={selectedTeam && <Dashboard selectedTeam={selectedTeam} />} isLoggedIn={isLoggedIn} />
                            <Route path="/neu" element={<Neu />} isLoggedIn={isLoggedIn} />
                        </Routes>
                        
                </div>
            </div>
            
        </div>
        </Router>
    );
}

// Privater Routenkomponente, um sicherzustellen, dass der Benutzer angemeldet ist, um auf das Dashboard zuzugreifen
function PrivateRoute({ component: Component, isLoggedIn, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Navigate to="/login" />
                )
            }
        />
    );
}

export default App;
