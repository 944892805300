// axiosInstance.js

import axios from 'axios';


const axiosInstance = axios.create({
    baseURL: 'https://football-tracker.de/api/',
    timeout: 5000,
    headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
    }
});

export default axiosInstance;
