// Dashboard.js

import React, { useEffect, useState } from 'react';
import TeamUserManagement from './TeamUserManagement';
import axiosInstance from './axiosInstance';

const Dashboard = ({ selectedTeam }) => {
    if (!selectedTeam) return <p>Please select a team to manage.</p>;
    
    return (
        <div>
            <h2>Admin Dashboard for {selectedTeam.name}</h2>
            <TeamUserManagement teamId={selectedTeam.id} />
        </div>
    );
}

export default Dashboard;
