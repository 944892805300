import React from 'react';
import { NavLink } from 'react-router-dom';

function Menu() {
    return(
        <div className="column is-2">
            <nav className="menu">
                <p className="menu-label">
                General
                </p>
                <ul className="menu-list">
                     <li><NavLink to="/" className={({ isActive, isPending, isTransitioning }) =>
                            [
                            isPending ? "pending" : "",
                            isActive ? "is-active" : "",
                            isTransitioning ? "transitioning" : "",
                            ].join(" ")
                        }>Dashboard</NavLink></li>
                    <li><NavLink to="/neu" className={({ isActive, isPending, isTransitioning }) =>
                            [
                            isPending ? "pending" : "",
                            isActive ? "is-active" : "",
                            isTransitioning ? "transitioning" : "",
                            ].join(" ")
                        }>Neu</NavLink></li>
                </ul>
            </nav>
        </div>
    );
}

export default Menu;