// Dashboard.js

import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosInstance';

function Neu() {
    return (
        <div>
            <h2 className='title'>Neu</h2>
            
        </div>
    );
}

export default Neu;
