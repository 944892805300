import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosInstance';

const TeamUserManagement = ({ teamId }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (teamId) {
            fetchUsers(teamId);
        }
    }, [teamId]);

    const fetchUsers = async (teamId) => {
        try {
            const response = await axiosInstance.get(`/user_management/organisations/${teamId}/users/`);
            setUsers(response.data);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch users.');
            setLoading(false);
        }
    };

    const handleAddUser = async (email) => {
        try {
            const response = await axiosInstance.post(`/user_management/organisations/${teamId}/users/`, { email });
            setUsers([...users, response.data]);
        } catch (error) {
            setError('Failed to add user.');
        }
    };

    const handleRemoveUser = async (userId) => {
        try {
            await axiosInstance.delete(`/user_management/organisations/${teamId}/users/${userId}/`);
            setUsers(users.filter(user => user.id !== userId));
        } catch (error) {
            setError('Failed to remove user.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h2>Team Users</h2>
            <ul>
                {users.map(user => (
                    <li key={user.id}>
                        {user.email} 
                        <button onClick={() => handleRemoveUser(user.id)}>Remove</button>
                    </li>
                ))}
            </ul>
            <input 
                type="email" 
                placeholder="Add user by email" 
                onKeyDown={(e) => {
                    if (e.key === 'Enter') handleAddUser(e.target.value);
                }} 
            />
        </div>
    );
};

export default TeamUserManagement;
